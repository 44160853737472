.animate-link {
    position: relative;
    overflow: hidden;
    display: flex;

    .hidden {
        opacity: 0;
        visibility: hidden;
    }

    &-tr {
        position: absolute;
        top: 0; left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;


        > * {
            transition: transform .5s ease;
        }

        > :first-child {
            transform: none;
        }
        > :last-child {
            transform: translateY(50%) rotate(-10deg);
        }

    }

    &:hover {
        .animate-link-tr {
            > :first-child {
                transform: translateY(-150%) rotate(-10deg);
            }
            > :last-child {
                transform: translateY(-100%) rotate(0deg);
            }
        }
    }




    .hover {
        position: absolute;
        top: 50%; left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        pointer-events: none;
    }
}