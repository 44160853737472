@use "@/assets/styles" as *;

.modal {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0; left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: color(white-100);
    transform: translate(10%, -150%) rotateZ(12deg) scale(1.5);
    opacity: 0;
    visibility: hidden;
    transition: transform 0.75s ease, visibility 0.75s ease, .3s opacity .5s;
    z-index: 1002;
    padding: rem(20);

    &.-active {
        transform: none;
        opacity: 1;
        visibility: visible;
        transition: .75s transform .3s ease, .75s opacity .3s;
    }

    &__cross {
        --size: #{rem(32)};
        position: absolute;
        right: rem(59);
        top: rem(53);
        width: var(--size);
        height: var(--size);
        cursor: pointer;

        @include mobile-sm {
            right: rem(20);
            top: rem(20);
        }
        
        &::before, &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: color(black-100);
            top: 0; left: 0;
            transform-origin: center center;
        }
        
        &::before {
            transform: translateY(calc(var(--size) / 2)) rotate(45deg);
        }
                
        &::after {
            transform: translateY(calc(var(--size) / 2)) rotate(-45deg);
        }
    }

    &__title {
        @include font-MazzardM-bold;
        font-size: font(96);
        color: color(black-100);

        @include mobile-sm {
            font-size: rem(45);
        }
    }

    &__subtitle {
        @include font-MazzardM-medium;
        color: rgba(115, 115, 115, 1);
        font-size: font(25);
        text-align: center;
        margin-top: rem(68);
        max-width: rem(1050);
        @include mobile-sm {
            margin-top: rem(16);
            font-size: rem(16);
        }
    }
}