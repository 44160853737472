.preview {
    position: absolute;
    inset: 0;
    z-index: 4;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}