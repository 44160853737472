@use "@/assets/styles" as *;

.form-preview {
  @include font-PlusJakartaDisplay-medium;
  display: none;
  background-color: color(white-100);

  @include tablet {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 rem(232);
  }

  @include mobile-sm {
    padding: 0 rem(50);
  }

  .title {
    margin-top: auto;
    font-size: font(55);

    @include mobile-sm {
      font-size: font(47);
    }

    div {
      display: inline-block;
      transform: translate(0, rem(-20));

      .avatar {
        position: relative;
        transform: translate(rem(-11), rem(24));
        width: rem(70);
        height: rem(70);
        border-radius: 100%;
        overflow: hidden;

        video {
          position: absolute;
          top: 0; left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  &__button {
    @include font-PlusJakartaDisplay-medium;
    display: flex;
    align-items: center;
    margin: rem(42) auto auto;
    padding: rem(20) rem(26);
    font-size: rem(16);
    border-radius: rem(70);
    background: color(purple-500);

    &:hover {
      background: color(purple-600);
    }

    img {
      width: rem(20);
      height: rem(12);
      margin: rem(4) 0 0 rem(7);
    }
  }
}