@use "@/assets/styles" as *;

.talk-button {
  position: relative;
  border-radius: rem(23);
  border: rem(2) solid var(--header-color);
  color: var(--header-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  padding: rem(12) rem(16) rem(9) rem(13);
  transition: color .3s, border-color .3s;
  cursor: pointer;

  @include mobile-sm {
    padding: rem(7) rem(6) rem(7) rem(15);
    border: 0;
    background-color: color(purple-300);
  }

  .hover {
    position: absolute;
    width: 100%;
    height: 200%;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%) scale(2);
    pointer-events: none;
  }

  .avatar {
    position: relative;
    display: none;
    @include mobile-sm {
      display: block;
      width: rem(31);
      height: rem(31);
      margin-left: rem(7);
      border: 0;
      background-color: color(purple-300);
      border-radius: 100%;
      overflow: hidden;
    }

    video {
      position: absolute;
      top: 0; left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 100%;
    }
  }

  div {
    &.dot {
      width: rem(6);
      height: rem(6);
      border-radius: 50%;
      background-color: color(green-700);

      @include mobile-sm {
        position: absolute;
        right: rem(2);
        top: rem(1);
        width: rem(10);
        height: rem(10);
        background-color: color(blue-700);
        border: rem(1) solid color(white-100);
      }
    }

    &.black-text {
      @include font-MazzardM-medium;
      font-size: font(16);
      padding-left: rem(9);

      @include mobile-sm {
        padding-left: 0;

      }
    }
  }
}

.button .-show-menu {
  visibility: hidden;
  opacity: 0;
}