@use "@/assets/styles" as *;

.choice-input {
  @include font-PlusJakartaDisplay-medium;
  width: fit-content;
  position: relative;
  cursor: pointer;

  .tr-y {
    > * {
      transition: transform .5s ease;
    }
    > :first-child {
      transform: none;
    }
    > :last-child {
      transform: translateY(80%) rotate(5deg);
    }
  }
  &:hover {
    .tr-y {
      > :first-child {
        transform: translateY(-180%) rotate(5deg);
      }
      > :last-child {
        transform: translateY(-100%) rotate(0deg);
      }
    }
  }

  .hover {
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  input {
    display: none;
  }
  
  label {
    position: absolute;
    top: 0; left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }

  .content {
    position: relative;
    width: max-content;
    display: inline-block;
    padding: rem(21) rem(42);
    background: color(white-100);
    border: rem(1.5) solid color(grey-100);
    border-radius: rem(60);
    font-size: rem(14);
    pointer-events: none;
    transition: border-color .3s ease;

    .text {
      position: relative;
      width: 100%;
      overflow: hidden;

      .hidden {
        opacity: 0;
        visibility: hidden;
        line-height: 120%;
      }

      .tr-y {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0; left: 0;
        width: 100%;
        height: 100%;
      }
    }

    @include mobile-sm {
      padding: rem(12) rem(24);
      font-size: rem(12);
    }

    &:hover {
      border-color: color(grey-200);
    }
  }

  input[type]:checked + .content {
    border: rem(1) solid color(black-100);
  }

  input[type="checkbox"]:checked ~ .form__check > .form__check_active {
    opacity: 1;
  }
}