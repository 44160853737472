@use "@/assets/styles" as *;

.controller {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    isolation: isolate;

    .controller-sections {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        .section {
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            height: 100%;

            &-inner {
                position: relative;
                width: 100%;
            }

            &-black {
                background: #000;
            }
        }
    }
}
