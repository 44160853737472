@use "sass:math";
@use "@/assets/styles" as *;

.random-spelling-disabled, .random-spelling {
    --spacer-w: #{rem(14.72)};
    --spelling-o: 1;
    --spelling-delay: 0s;
    --spelling-o-dur: 0s;
    --spelling-delay-c: 1;

    span {
        display: inline-block;
        line-height: 105%;
        @include tablet {
            line-height: 105%;
        }
        @include mobile-sm {
            line-height: 104%;
        }
    }

    > span {
        opacity: var(--spelling-o);
    }

    .spacer {
        width: var(--spacer-w);
        min-width: var(--spacer-w);
    }
}

.random-spelling {
    @for $i from 1 through 800 {
        $delay: calc(var(--spelling-delay) + #{math.random()}s * var(--spelling-delay-c));
        > :nth-child(#{$i}) {
            transition: var(--spelling-o-dur) opacity $delay;
        }
    }
}