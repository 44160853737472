@use "@/assets/styles" as *;

.eye-text {
  animation: rotate 5s infinite linear;
  transform-origin: center center;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.eye-top {
  transform: translateY(20px) scaleY(.5);
  opacity: 0;
  transition: .5s transform ease, .3s opacity;
}
.eye-bottom {
  transform: translateY(45px) scaleY(.5);
  opacity: 0;
  transition: .5s transform ease, .3s opacity;
}

.-not-active {
  .eye-top, .eye-bottom {
    transform: none;
    opacity: 1;
  }
}