.vimeo-main {
    width: 100%;
    height: 100%;
    position: relative;

    > .vimeo-main-video {
        pointer-events: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: relative;
        z-index: 2;
    }

    > .vimeo-main-wrap-video {
        z-index: 1;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
    }

    .vimeo-main-wrap {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: calc(100% - 150px);
        z-index: 4;
        cursor: pointer;
    }
}
.vimeo-play-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70px;
    height: 70px;
    z-index: 5;

    display: flex;
    justify-content: center;
    align-items: center;
    border: .5px solid var(--color-black-100);
    border-radius: 50%;
    // opacity: 0.5;
    background: #FFF;
    transition: opacity 0.2s ease-in-out;

    .triangle {
        border-bottom: 0.375rem solid transparent;
        border-left: 0.75rem solid #000;
        border-top: 0.375rem solid transparent;
        opacity: 0.5;
        -webkit-transform: translate(0.125rem);
        transform: translate(0.125rem);
    }

    &--active {
        opacity: 0;
    }
}
