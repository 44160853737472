@use "@/assets/styles" as *;


.few-people {
    .spelling {
        --spelling-delay: .3s;
        --spelling-tr-dur: .5s;
        --spelling-tr: #{rem(50)};
        --spelling-delay-c: 150;
        @include mobile-sm {
            --spacer-w: #{rem(5)};
        }
    }

    h2 {
        .-lighten {
            color: color(white-100);
        }
    }
}

.-from-active {
    .few-people__circles {
        > div {
            opacity: 1;
        }
    }
}
.-to-prev {
    .few-people__circles {
        >:first-child {
            transition: .15s opacity;
        }
        >:nth-child(2) {
            transition: .3s opacity;
        }
        >:nth-child(3) {
            transition: .45s opacity;
        }
        > div {
            opacity: 0;
        }
    }
}

.few-people__circles {
    > div {
        transition: 1s opacity ease;
        opacity: 0;
    }
}
.-active {
    .few-people__circles {
        >:first-child {
            transition: .5s opacity .5s;
        }
        >:nth-child(2) {
            transition: .5s opacity .7s;
        }
        >:nth-child(3) {
            transition: .5s opacity 1s;
        }
        > div {
            opacity: 1;
        }
    }
}