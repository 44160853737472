@use "@/assets/styles" as *;

.create {
  position: absolute;
  top: 0; left: 0;
  @include font-PlusJakartaDisplay-bold;
  width: 100vw;
  height: 100vh;
  background: color(black-100);
  color: color(pink-300);
  overflow: hidden;
  pointer-events: none;
  z-index: 1;

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
  }

  .text {
    width: 100%;
    transform: rotate(-15deg);
  }

  .row {
    width: 130vw;
    transform: translateX(-15vw);
    height: rem(211);
    background-image: url("./text.webp");
    background-repeat: repeat-x;
    background-size: auto 100%;
    opacity: 0;
    @include mobile-sm {
      height: rem(70);
      width: 150vw;
      transform: translateX(-25vw);
    }
  }



  .row_five,
  .row_six,
  .row_seven {
    display: none;

    @include mobile-sm {
      display: flex;
    }
  }
}