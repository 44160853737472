@use "@/assets/styles" as *;

.jumper {
    --background-color: #{color(black-100)};
    --content-color: #{color(white-100)};

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    z-index: 10000;

    &__circle {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: var(--background-color);
        color: var(--content-color);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: .5s color .3s ease, .5s background-color .3s ease;

        .logo {
            position: relative;
            width: rem(220);
            height: rem(50);

            svg {
                position: absolute;
                top: 0; left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;

                path {
                    stroke-width: 1;
                    stroke-dasharray: 150 150;
                    stroke-dashoffset: 150;
                }
            }
        }
    }
}