@use "@/assets/styles" as *;

.few-people {
    position: relative;
    width: 100%;
    height: 200vh;
    overflow: hidden;

    &__container {
        position: absolute;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100vh;
        padding: 0 rem(100);
        @include mobile-sm {
            padding: 0 rem(20);
        }
    }

    h2 {
        position: relative;
        @include font-PlusJakartaDisplay-regular;
        font-size: rem(75);
        color: #5F5F5F;
        max-width: rem(900);
        padding-left: rem(80);
        padding-top: rem(150);
        @include tablet {
            padding-left: rem(40);
            font-size: rem(50);
        }
        @include mobile-sm {
            padding-left: rem(10);
            padding-top: rem(80);
            font-size: rem(35);
            max-width: rem(320);
        }

        .element {
            @include font-MazzardM-medium;
            position: absolute;
            top: 0; left: 0;
            font-size: rem(290);
            line-height: 100%;
            transform: translateY(-50%);
            @include tablet {
                font-size: rem(190);
                transform: translateY(-20%);
            }

            @include mobile-sm {
                font-size: rem(100);
            }
        }
    }

    &__scroller {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        p {
            position: absolute;
            @include font-PlusJakartaDisplay-regular;
            font-size: rem(20);
            color: rgba(255, 255, 255, .5);
            text-align: right;
            width: rem(456);
            right: 0;
            top: calc(100% + rem(243));

            @include laptop-xlg-2000-1000 {
                top: calc(100% + rem(143));
            }

            @include laptop-xlg {
                top: calc(100% + rem(125));
            }

            @include mobile-sm {
                top: calc(100% + rem(100));
                font-size: rem(15);
                width: rem(200);
            }
        }
    }

    &__circles {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: -1;

        > div {
            width: rem(460);
            height: rem(460);
            border-radius: 100%;
            background: #121212;
            transform: translateX(20%);
            @include mobile-sm {
                width: rem(260);
                height: rem(260);
            }
        }

    }

    &__progress {
        position: relative;
        height: rem(250);
        width: rem(4);
        background: #464646;
        border-radius: rem(30);

        > div {
            position: absolute;
            top: 0; left: 0;
            height: 100%;
            width: 100%;
            background: color(white-100);
            border-radius: rem(30);
            max-height: 0;
        }
    }
}