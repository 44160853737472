* {
  margin: 0;
  padding: 0;
  outline: none;
  border: none;
  text-decoration: none;
  box-sizing: border-box;
}

ul {
  list-style: none;
}

span {
  display: inline-block;
}

a {
  color: inherit;
}

a, button {
  cursor: pointer;
}
