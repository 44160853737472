@use "@/assets/styles" as *;

.portfolio {
  @include font-PlusJakartaDisplay-medium;
  position: relative;
  background: color(black-100);
  color: color(white-100);
  overflow: hidden;
  width: 100%;

  &__sticky {
    height: 100vh;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__container {
    position: relative;
    height: 200vh;
    overflow: hidden;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 rem(60);
    margin-bottom: rem(45);

    @include laptop-xlg {
      margin-bottom: rem(90);
    }

    @include tablet {
      flex-direction: column;
      align-items: end;
      margin-bottom: rem(64);
    }

    @include mobile-sm {
      padding: 0 rem(20);
      margin-bottom: rem(34);
    }

    h2 {
      font-size: font(30);
      font-weight: 500;
      width: rem(900);

      @include tablet {
        margin: 0 0 rem(48);
      }

      @include mobile-sm {
        font-size: rem(22);
        width: rem(350);
        margin: 0 0 rem(19);
      }
    }
  }

  &__socials {
    display: flex;
    position: relative;

    >:last-child {
      margin-left: rem(17);
    }

    .hover {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: rem(200);
      height: rem(200);
    }

    .social-tr {
      transition: .3s ease;
    }

    .social {
      transition: background-color .3s;

      &:hover {
        background: color(white-100);
      }
    }
  }

  .pagination {
    margin: rem(48) rem(60) 0;

    @include laptop-xlg {
      margin: rem(71) rem(60) 0;
    }

    @include mobile-sm {
      margin: rem(22) rem(20) 0;
    }
  }

  &__animation {
    position: relative;
    width: 100%;
    margin-top: rem(-300);
    z-index: 1;
    @include mobile-sm {
      padding: 0 rem(20);
      margin-top: rem(-100);
    }

    .arm {
      position: absolute;
      width: rem(120);
      height: rem(120);
      top: 0;
      right: rem(50);
      transform: translate(10%, -50%);

      @include laptop-xlg {
        width: rem(200);
        height: rem(200);
        top: 0;
        right: rem(20);
        transform: translate(10%, -50%);
      }

      @include mobile-sm {
        width: rem(100);
        height: rem(100);
        transform: translate(30%, -50%);
      }

      > div, video {
        position: absolute;
        top: 0; left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      > div {
        border-radius: 100%;
        overflow: hidden;
        border: 1px solid color(white-100);
      }
    }

    .line {
      width: 100%;
      height: auto;

      @include mobile-sm {
        padding-left: 1px;
        path {
          stroke-width: 2px; 
        }
      }
    }
  }

  &__line {
    position: relative;
    height: 300vh;
    margin-top: rem(-10);
    margin-bottom: -220vh;

    >div {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(calc(-50% + .5px));
      width: 1px;
      height: 100%;
      background: color(white-100);
    }
  }
}


.portfolio {
  &__following {
    position: relative;
    width: 100%;
    height: 300vh;
    background: color(white-100);

    @include mobile-sm {
      height: 200vh;
    }

    .following-sticky {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background: color(white-100);
      perspective: rem(800);
      display: flex;
      justify-content: center;
      align-items: center;
      isolation: isolate;

      .canvas,
      canvas {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    .following__cube-container {
      transform-style: preserve-3d;
    }

    .following__cube {
      --width: #{rem(604)};
      --height: #{rem(482)};
      position: relative;
      width: var(--width);
      height: var(--height);
      transform-style: preserve-3d;
      transform: translate3d(0, 0, rem(-300));
      // pointer-events: none;
      z-index: 1;

      @include mobile-sm {
        --width: #{rem(270)};
        --height: #{rem(213)};
      }

      .cude__wrapper {
        position: absolute;
        transform-style: preserve-3d;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      .cube__center {
        >:first-child {
          margin-top: rem(8);
        }
        font-size: rem(30.5);
        text-transform: uppercase;
        // transform: translate3d(0, 0, calc(var(--width) / 2));
        transform: translate3d(calc(var(--width) / -2), 0, 0) rotateY(-90deg);

        @include mobile-sm {
          display: flex;
          justify-content: center;
          align-items: flex-start;
          .random-spelling {
            --spacer-w: #{3.3%};
            display: flex;
            flex-wrap: wrap;
            margin-left: 2px;
          }
          > .random-spelling > * {
            width: calc(100% / 33);
            display: flex;
            justify-content: center;
          }
          font-size: rem(12);
          >:first-child {
            margin-top: rem(-11);
          }
        }
      }

      .cube__right {
        transform: translate3d(0, 0, calc(var(--width) / 2));

        // transform: translate3d(calc(var(--width) / 2), 0, 0) rotateY(90deg);

        svg {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }

        .cube-link {
          position: relative;
          color: color(black-100);
          transition: .3s color ease;
          cursor: pointer;

          &:hover {
            color: #D0C1FF;
          }

          @include mobile-sm {
            pointer-events: none;
          }


        }
      }

      .cube__center,
      .cube__right {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        color: color(black-100);
        @include font-MazzardM-bold;
        letter-spacing: 0;
        overflow: hidden;
        line-height: 100%;
        backface-visibility: hidden;
      }
    }
  }

  .cursor-followed-wrapper {
    transform: translateZ(100px);
    mix-blend-mode: screen;
    z-index: 10;
    
    .cursor-followed,
    .cursor-followed>:nth-child(1),
    .cursor-followed>:nth-child(2) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
    }


    .cursor-followed {
      width: rem(400);
      height: rem(400);
      background-color: #71709F;
      // opacity: 0;

      > :nth-child(1) {
        width: 80%;
        height: 80%;
        background: #919FD2;
      }

      > :nth-child(2) {
        width: 50%;
        height: 50%;
        background: #DAD2F4;
      }
    }
  }
}