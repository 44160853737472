@use "@/assets/styles" as *;

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  // overflow: hidden; 
  width: rem(1078);
  padding: rem(80) rem(23) rem(81) rem(74);
  border-radius: rem(48);

  height: calc(100vh - rem(152));
  margin-top: rem(120);

  @include laptop-xlg {
    padding: rem(30) rem(23) rem(30) rem(74);
    width: rem(1000);
  }

  @include tablet {
    width: rem(700);
    padding: rem(6) rem(8) rem(40) rem(48);
    border-radius: rem(24);
    margin-top: rem(0);
  }

  @include mobile-sm {
    height: auto;
    width: calc(100vw - rem(40));
    padding: rem(6) rem(8) rem(26) rem(24);
    border-radius: rem(15);
  }


  &.card-crop {
    .card__img {
      overflow: hidden;
    }
  }

  .card__img {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: rem(217);
    // overflow: hidden;
    height: 30vh;
    width: 60vh;
    min-width: rem(450);
    z-index: 1;


    @include tablet {
      width: rem(300);
      height: rem(200);
      min-width: auto;
    }

    @include mobile-sm {
      width: rem(141);
      height: rem(141);
      border-radius: 50%;
    }

    .video-wrap {
      border-radius: rem(217);
      // overflow: hidden;
    }

    .video-wrap, .video-zoom {
      position: absolute;
      top: 0; left: 0;
      width: 100%;
      height: 100%;
    }

    .video-clip {
      position: absolute;
      top: 0; left: 0;
      width: 100%;
      height: 100%;
      transform: scale(1);
    }

    .video-zoom {}

    video {
      border-radius: rem(217);
      position: absolute;
      top: 50%; left: 50%;
      transform: translate(-50%, -50%) scale(1.1);
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .frame-by-frame {
      position: absolute;
      top: 50%; left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .frame-by-frame canvas {
      position: absolute;
      top: 50%; left: 50%;
      transform: translate(-50%, -50%) scaleX(1.13) scaleY(1.6);
      width: 100%;
      height: 100%;
      object-fit: cover;

      @include mobile-sm {
        width: 130%;
        height: 130%;
        transform: translate(-50%, -50%) scaleX(1.2) scaleY(.8);
      }
    }
  }

  img {
    min-height: rem(434);
    min-width: rem(645);

    @include laptop-xlg {
      min-height: rem(234);
      min-width: rem(345);
    }

    @include tablet {
      min-height: rem(200);
      min-width: rem(300);
    }
  }

  h3 {
    @include font-PlusJakartaDisplay-medium;
    width: rem(840);
    padding: rem(32) 0 rem(15);
    font-size: font(80);
    line-height: rem(92);

    @include laptop-xlg {
      padding: rem(30) 0 rem(15);
    }

    @include tablet {
      width: auto;
      padding: rem(24) 0 rem(8);
      font-size: font(30);
      line-height: rem(30);
    }

    @include mobile-sm {
      width: rem(272);
      padding: rem(35) 0 rem(0);
      font-size: 23px;
      line-height: 1.08;
    }
  }

  .card__content-bottom {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: column;
  }

  .text__container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    height: 100%;
    flex: 1;

    p {
      @include font-PlusJakartaDisplay-regular;
      margin-right: rem(250);
      font-size: font(20);
      line-height: rem(25);
      color: color(grey-300);

      // @include laptop-lg-xlg-1500-900 {
      //   font-size: rem(16);
      //   margin-right: rem(100);
      // }

      @include tablet {
        margin-right: rem(48);
      }

      @include mobile-sm {
        align-items: flex-end;
        margin-top: 9px;
        margin-right: 23px;
        font-size: 12px;
        line-height: 1.3;
      }
    }

    .card__index {
      @include font-PlusJakartaDisplay-medium;
      font-size: font(80);
      line-height: normal;
      color: color(gray-150);
      margin-right: rem(51);
      margin-top: auto;

      @include mobile-sm {
        font-size: font(26);
        margin-right: rem(16);
      }
    }
  }
}

.image__container {
  display: flex;
  justify-content: flex-start;
}

.circle__crop {
  position: absolute;
  top: 0; left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circle__container {
  position: absolute;
  left: rem(-480);
  top: rem(200);
  transform: rotate(-45deg);

  @include tablet {
    left: rem(-200);
    top: rem(120);
  }

  @include mobile-sm {
    left: rem(-120);
    top: rem(64);
  }

  .circle {
    position: absolute;
    width: rem(583);
    height: rem(583);
    border: rem(1) solid color(gray-150);
    border-radius: 50%;

    @include tablet {
      width: rem(300);
      height: rem(300);
    }

    @include mobile-sm {
      width: rem(190);
      height: rem(190);
    }
  }

  & > :first-child {
    left: rem(360);

    @include tablet {
      left: rem(180);
    }

    @include mobile-sm {
      left: rem(100);
    }
  }
}