@use "@/assets/styles" as *;

.portfolio-circle {
    position: absolute;
    top: 0; left: 0;
    width: 100vw;
    height: 100vh;

    .circle {
        position: absolute;
        bottom: 50%; left: 50%;
        transform: translateX(-50%);
        width: 200vw;
        height: 200vw;
        border-radius: 100%;
        background-color: color(black-100);
    }
}